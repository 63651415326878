.TimelineFooter {
  width: 100%;
  height: 32px;
  background: var(--base-dark-a);
  position: absolute;
  z-index: 4;
  bottom: 0;
  border-bottom: 1px solid var(--teal-b-opaque);
  box-shadow: 0 20px 0 -10px var(--base-dark-a);

  transition: all var(--theme-toggle-transition);
}

:global(.theme-light) .TimelineFooter {
  background: var(--base-light-a);
  box-shadow: 0 10px 0 -10px var(--base-light-a);
}

.zoomBarContainer {
  width: 100%;
  height: 12px;
  margin-top: 3px;
  position: relative;
  top: 8px;
  left: 250px;
}

.zoomBar {
  height: 12px;
  background: var(--base-dark-b);
  position: absolute;
  top: 0;
  left: 0;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .zoomBar {
  background: var(--base-light-b);
}

.zoomHandle {
  position: absolute;
  width: 4px;
  height: 12px;
  background: var(--teal-b);
}

.zoomHandleLeft {
  composes: zoomHandle;
  left: 0;
  border-radius: 2px 0 0 2px;
}

.zoomHandleRight {
  composes: zoomHandle;
  right: 0;
  border-radius: 0 2px 2px 0;
  cursor: ew-resize;
}
