.iconContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.iconContainer svg {
  /* stroke: none; */
  display: block;
}
