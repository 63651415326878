.input {
  width: 55px;
  height: 23px;
  padding: 0 8px;
  background: var(--base-dark-d);
  color: var(--teal-c);
  border: 1px solid transparent;
  border-radius: 2px;
  font-size: 12px;
  margin: auto 0;
  outline: none;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .input {
  background: var(--base-light-c);
}

.input:not(:disabled) {
  border: 1px solid var(--teal-c);
}

:global(.theme-light) .input:not(:disabled) {
  border: 1px solid var(--base-light-d);
}

:global(.theme-light) .input:not(:disabled) {
  background: var(--base-light-b);
}

.input:focus {
  border: 1px solid var(--yellow-a);
}

:global(.theme-light) .input:focus {
  border: 1px solid var(--teal-d);
}
