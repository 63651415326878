.VisualPreviewContent {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ResizeHandle {
  position: absolute;
  display: none;
  top: 50%;
  left: -3px;
  transform: translateY(-50%);
}

.ResizeHandleMobileContainer {
  width: 12px;
  position: absolute;
  background: var(--base-dark-a);
  z-index: 1;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .ResizeHandleMobileContainer {
  background: var(--base-light-a);
}

.ResizeHandleMobile {
  composes: ResizeHandle;
  display: block;
  left: 4px;
}

@media (min-width: 768px) {
  .ResizeHandle {
    display: block;
  }

  .ResizeHandleMobileContainer,
  .ResizeHandleMobile {
    display: none;
  }
}
