:root {
  --base-dark-a: #202121;
  --base-dark-b: #242626;
  --base-dark-c: #282a2b;
  --base-dark-d: #2c2f30;

  --base-light-a: #fcffff;
  --base-light-b: #f5f7f7;
  --base-light-c: #ebf2f2;
  --base-light-d: #afb2b3;
  --base-light-e: #dee5e5;

  --teal-a: #408080;
  --teal-b: #59b2b2;
  --teal-b-opaque: #59b2b215;
  --teal-c: #7a8f99;
  --teal-d: #515f66;
  --teal-e: #13bf94;

  --red-b: #f27b79;

  --yellow-a: #f2e76d;

  --theme-toggle-transition: 0.2s ease-in-out;
  --hover-transition: 0.15s;

  --header-heights: 35px;
}

.theme-dark {
  background: var(--base-dark-a);
  transition: background var(--theme-toggle-transition);
  min-height: 100vh;
}

.theme-light {
  background: var(--base-light-a);
  transition: background var(--theme-toggle-transition);
  min-height: 100vh;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  touch-action: none;
  overscroll-behavior-x: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  background: var(--base-dark-a);
}

body,
input,
button,
textarea {
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  letter-spacing: 0.7px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.05);
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.theme-light ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.05);
}
