.ScreenHeader {
  height: var(--header-heights);
  display: flex;
}

.ScreenHeaderTitle {
  position: relative;
  color: var(--base-light-a);
  font-size: 13px;
  line-height: var(--header-heights);
  flex-grow: 1;
  overflow: hidden;
  background: var(--base-dark-b);

  transition: all var(--theme-toggle-transition);
}

.toggle {
  display: inline-block;
  margin-left: 6px;
}

.titleText {
  padding-left: 8px;
}

:global(.theme-light) .ScreenHeaderTitle {
  color: var(--base-dark-a);
  background: var(--base-light-b);
}

.ScreenHeaderTitle:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: var(--teal-a);
  width: 100%;
  height: 1px;
}

.ScreenHeaderTitle:after {
  content: '';
  position: absolute;
  z-index: 2;
  right: -12px;
  top: -5px;
  transform: rotate(-10deg);
  background: var(--base-dark-a);
  width: 20px;
  height: 120%;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .ScreenHeaderTitle:after {
  background: var(--base-light-a);
}

.ScreenHeaderButtons {
  background: var(--base-dark-a);
  display: flex;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .ScreenHeaderButtons {
  background: var(--base-light-a);
}
