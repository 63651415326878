/* Mobile first! */

.app {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container {
  background: var(--base-dark-b);
  position: absolute;
  left: 0;
  width: 100%;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .container:not(.CodeEditorContainer) {
  background: var(--base-light-b);
}

.ProjectExplorerContainer {
  composes: container;
  grid-area: ProjectExplorer;
  height: 100%;
  width: calc(100% - 24px);
}

.CodeEditorContainer {
  composes: container;
  grid-area: CodeEditor;
  height: 100%;
  background: transparent;
  transform: translateX(var(--CodeEditorMobileX));
}

.VisualPreviewContainer {
  composes: container;
  grid-area: VisualPreview;
  height: calc(100% - var(--TimelineHeight));
  transform: translateX(var(--VisualPreviewMobileX));
  z-index: 2;
}

.TimelineContainer {
  composes: container;
  grid-area: Timeline;
  height: var(--TimelineHeight);
  border-top: 12px solid var(--base-dark-a);
  bottom: 0;
  transform: translateX(var(--VisualPreviewMobileX));
  z-index: 1;

  transition: background var(--theme-toggle-transition),
    border-top var(--theme-toggle-transition);
}

:global(.theme-light) .TimelineContainer {
  border-top: 12px solid var(--base-light-a);
}

@media (min-width: 768px) {
  .app {
    display: grid;
    grid-template-columns:
      var(--ProjectExplorerWidth)
      1fr
      var(--VisualPreviewWidth);
    grid-template-rows: 1.5fr var(--TimelineHeight);
    gap: 4px 4px;
    grid-template-areas:
      'ProjectExplorer CodeEditor VisualPreview'
      'ProjectExplorer Timeline Timeline';
  }

  .container {
    position: relative;
    width: auto;
    height: unset;
    border-top: none;
    transform: translateX(0);
  }

  .CodeEditorContainer {
    overflow: hidden;
  }

  .VisualPreviewContainer {
    z-index: 0;
  }

  :global(.theme-light) .TimelineContainer {
    border-top: 0;
  }

  :global(.previewOnly).app {
    display: block;
  }

  :global(.previewOnly) .VisualPreviewContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }
}
