.ContextMenu {
  position: fixed;
  margin: 0;
  z-index: 2;
  background: var(--base-dark-b);
  font-size: 14px;
  border: 0;
  border-radius: 2px;
  padding: 0;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  width: 198px;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .ContextMenu {
  background: var(--base-light-b);
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: transparent;
  color: var(--base-light-a);
  border: none;
  margin: auto 0;
  cursor: pointer;
  padding: 8px;
}

:global(.theme-light) .button {
  color: var(--base-dark-a);
}

.button:hover {
  background: var(--base-dark-d);
}

:global(.theme-light) .button:hover {
  background: var(--base-light-e);
}

.text {
  font-size: 14px;
}

.shortcut {
  font-size: 12px;
}
