.CodeComment {
  position: absolute;
  width: 100%;
  height: var(--code-comment-height);
  bottom: 0;

  transition: height var(--theme-toggle-transition);
}

.buttonToggle {
  width: 100%;
}

.headingText {
  color: var(--base-light-a);
  padding-left: 32px;
  font-size: 13px;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .headingText {
  color: var(--base-dark-a);
}

.content {
  background: var(--base-dark-b);
  padding: 16px;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .content {
  background: var(--base-light-b);
}
