.ProjectExplorer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--base-dark-a) !important;
}

:global(.theme-light) .ProjectExplorer {
  background: var(--base-light-a) !important;
}

.ResizeHandle {
  display: none;
  position: absolute;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .ResizeHandle {
    display: block;
  }
}
