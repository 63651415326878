.ProjectHistory {
  width: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  top: 0;
}

.count {
  color: var(--base-light-a);
  font-size: 12px;
  line-height: var(--header-heights);
  padding: 0 10px;
  text-align: right;
}

.info {
  color: var(--base-dark-a);
  background: var(--teal-b-opaque);
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  width: 20px;
  margin: 6px;
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 10px;
  outline: none;
}

.infoBrowse {
  background: var(--teal-b);
  cursor: pointer;
}

.infoOverwrite {
  background: var(--red-b);
  cursor: pointer;
}

.list {
  /* overflow-y: auto; */
  /* position: absolute;
  width: 100%;
  height: calc(100% - 70px); */
}

.action {
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  color: var(--teal-c);
  font-size: 12px;
  line-height: 35px;
  margin: 0;
  padding: 0 16px;
  outline: none;
  cursor: pointer;

  transition: all 0.15s;
}

.actionSelected {
  color: var(--teal-b);
  background: var(--teal-b-opaque);
  transition: all var(--theme-toggle-transition);
}

:global(.theme-light) .actionSelected {
  color: var(--base-dark-a);
}

.action:hover {
  background: var(--teal-b-opaque);
  transition: all 0s;
}

.actionText {
  margin-left: 16px;
}

.commentIconContainer {
  width: 20px;
  display: inline-flex;
  flex: 1;
  justify-content: flex-end;
}

.commentIcon {
  width: 20px;
  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .actionSelected .commentIcon {
  color: var(--teal-b);
}

.contentContainer {
  background: var(--base-dark-b);
  overflow-y: auto;
  transition: height ease-in-out 0.2s, background var(--theme-toggle-transition);
}

:global(.theme-light) .contentContainer {
  background: var(--base-light-b) !important;
}

.footer {
  height: 35px;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--base-dark-a);
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--teal-b-opaque);

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .footer {
  background: var(--base-light-a);
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.playbackButton {
  height: 25px;
  min-width: 16px;
}

:global(.theme-light) .playbackButton {
  color: var(--teal-c);
}

.playbackButton:disabled {
  opacity: 0.3;
  cursor: default;
}

.modalButton {
  flex-grow: 1;
}

.modalButton:not(:first-child) {
  margin-left: 16px;
}
