.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--base-dark-a);
  opacity: 0.8;
  backdrop-filter: blur(5px);
}

.modal {
  position: fixed;
  margin: 0;
  z-index: 2;
  background: var(--base-dark-b);
  color: var(--base-light-a);
  font-size: 14px;
  border: 0;
  border-radius: 2px;
  padding: 0;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.2);
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .modal {
  background: var(--base-light-b);
}

.header {
  background-color: var(--base-dark-a);
  padding: 0 16px;
  font-weight: 500;
  line-height: 35px;
  display: flex;
  justify-content: space-between;

  transition: background var(--theme-toggle-transition),
    color var(--theme-toggle-transition);
}

:global(.theme-light) .header {
  background: var(--base-light-a);
  color: var(--base-dark-a);
}

.closeButton {
  background-color: transparent;
  border: 0;
  color: var(--teal-c);
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  opacity: 0.75;
  transition: opacity var(--hover-transition);
}

.body {
  padding: 16px;
}

.body p {
  margin-bottom: 16px;
  color: var(--base-light-a);
  transition: background var(--theme-toggle-transition),
    color var(--theme-toggle-transition);
}

:global(.theme-light) .body p {
  color: var(--base-dark-a);
}
