.inputsList {
  list-style: none;
}

.inputRow {
  position: relative;
  padding: 0 16px 0 32px;
  height: 39px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--base-dark-d);

  transition: border-bottom var(--theme-toggle-transition);
}

:global(.theme-light) .inputRow {
  border-bottom: 1px solid var(--base-light-d);
}

.inputHeading {
  font-weight: 300;
  font-size: 12px;
  line-height: 39px;
  color: var(--teal-c);
}
