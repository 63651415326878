.ButtonToggle {
  background: transparent;
  height: var(--header-heights);
  width: 26px;
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: middle;
  cursor: pointer;
  text-align: left;
}

.isExpanded {
  composes: ButtonToggle;
}

.ButtonToggle:before {
  content: '';
  position: absolute;
  top: calc(50% - 2px);
  left: 7px;
  width: 12px;
  height: 2px;
  background: var(--teal-c);

  transition: background var(--theme-toggle-transition);
}

.ButtonToggle:after {
  content: '';
  position: absolute;
  top: calc(50% - 7px);
  left: 12px;
  width: 2px;
  height: 12px;
  background: var(--teal-c);

  transition: top 0.2s ease-in-out, height 0.2s ease-in-out,
    background var(--theme-toggle-transition);
}

.ButtonToggle:hover:before,
.ButtonToggle:hover:after {
  background: var(--base-light-a);
}

:global(.theme-light) .ButtonToggle:hover:before,
:global(.theme-light) .ButtonToggle:hover:after {
  background: var(--base-dark-a);
}

.isExpanded:after {
  top: calc(50% - 0px);
  height: 0px;
}
