.ScrubBar {
  position: relative;
}

.playhead {
  position: absolute;
  bottom: 0;
  width: 5px;
  height: 20px;
  background: #afb2b3;
  z-index: 3;

  transition: opacity 0.15s ease-in-out;
}

.playheadHidden {
  opacity: 0.15;
  pointer-events: none;
}

.playheadHandle {
  position: absolute;
  left: 2px;
  width: 1px;
  height: 500px;
  background: #afb2b3;
}

.playheadHandle:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 100%;
  left: -5px;
  cursor: ew-resize;
}

.zoomBarContainer {
  width: 100%;
  height: 8px;
  margin-top: 3px;
  background: var(--base-dark-b);
  position: relative;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .zoomBarContainer {
  background: var(--base-light-e);
}

.zoomBar {
  height: 8px;
  background: var(--base-dark-a);
  position: absolute;
  top: 0;
  left: 0;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .zoomBar {
  background: var(--base-light-a);
}

.zoomHandle {
  position: absolute;
  width: 4px;
  height: 8px;
  background: var(--teal-b);
}

.zoomHandleLeft {
  composes: zoomHandle;
  left: 0;
  border-radius: 2px 0 0 2px;
}

.zoomHandleRight {
  composes: zoomHandle;
  right: 0;
  border-radius: 0 2px 2px 0;
  cursor: ew-resize;
}
