.Button {
  border: 1px solid transparent;
  border-radius: 2px;
  background: var(--teal-b);
  color: var(--base-dark-a);
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  outline: none;
}

.inverted {
  border: 1px solid var(--teal-b);
  background: transparent;
  color: var(--teal-b);
}

.danger {
  background: var(--red-b);
}
