.likes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.likeButton {
  display: inline-block;
  padding: 0;
  width: 17px;
}

.likeButton svg {
  fill: none;
  stroke: var(--teal-c);
}

.likeButtonActive svg {
  fill: var(--red-b);
  stroke: var(--red-b);
}

.likesCount {
  display: inline-block;
  color: var(--base-light-a);
  font-size: 12px;
  line-height: 12px;
  margin: 0 12px;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .likesCount {
  color: var(--base-dark-a);
}

.iLike {
  color: var(--red-b);
}
