.forks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forkButton {
  display: inline-block;
  padding: 0;
  width: 17px;
  color: var(--teal-c);
}

.forksCount {
  display: inline-block;
  color: var(--base-light-a);
  font-size: 12px;
  line-height: 12px;
  margin: 0 12px;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .forksCount {
  color: var(--base-dark-a);
}
