.ExpandingTextarea {
  background: transparent;
  border: none;
  color: var(--base-light-a);
  font-size: 13px;
  padding: 0;
  display: block;
  width: calc(100% - 2px);
  margin-left: 1px;

  transition: color var(--theme-toggle-transition),
    background var(--theme-toggle-transition);
  outline: none;
  font-size: 16px;
  max-width: 100%;
  /* min-width: 100%; */
  min-height: 50px;
}

.ExpandingTextarea:disabled {
  resize: none;
}

.ExpandingTextarea:not(:disabled) {
  background: var(--base-dark-d);
  padding: 4px 8px;
  border-radius: 2px;
}

:global(.theme-light) .ExpandingTextarea {
  color: var(--base-dark-a);
}

:global(.theme-light) .ExpandingTextarea:not(:disabled) {
  background: var(--base-light-a);
}

.ExpandingTextarea:focus {
  box-shadow: 0 0 0 1px var(--teal-b);
}

/* https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/ */
.growWrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}

.growWrap::after {
  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}

.growWrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.growWrap > textarea,
.growWrap::after {
  /* Identical styling required!! */

  padding: 4px 8px;
  /* margin-bottom: 16px; */
  min-height: 50px;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
