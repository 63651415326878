.ProjectMeta {
  background: var(--base-dark-b) !important;
  border-bottom: 4px solid var(--base-dark-a);

  transition: all var(--theme-toggle-transition);
}

:global(.theme-light) .ProjectMeta {
  background: var(--base-light-b) !important;
  border-bottom: 4px solid var(--base-light-a);
}

.header {
  height: var(--header-heights);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: var(--base-dark-a);

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .header {
  background: var(--base-light-a);
}

.titleBackground {
  height: 100%;
  position: relative;
  display: flex;
  padding: 0 8px 2px 10px;
  flex: 1;
  align-items: center;
  background: var(--base-dark-b);
  border-bottom: 1px solid var(--teal-a);

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .titleBackground {
  background: var(--base-light-b);
}

.titleBackground:after {
  content: '';
  position: absolute;
  z-index: 2;
  right: -2px;
  top: -3px;
  transform: rotate(-10deg);
  background: var(--base-dark-a);
  width: 6px;
  height: 39px;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .titleBackground:after {
  background: var(--base-light-a);
}

.headerActions {
  display: flex;
  height: 100%;
  padding-left: 8px;
  position: relative;
  z-index: 2;
}

.contentContainer {
  overflow: hidden;
  transition: height ease-in-out 0.2s;
}

.content {
  padding: 16px;
  color: var(--teal-c);
  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .content {
  color: var(--teal-d);
}

.row {
  margin-bottom: 16px;
  display: block;
}

.input {
  background: transparent;
  border: none;
  color: var(--base-light-a);
  font-size: 13px;
  padding: 0;
  display: block;
  width: 100%;

  transition: color var(--theme-toggle-transition),
    background var(--theme-toggle-transition);
}

:global(.theme-light) .input {
  color: var(--teal-d);
}

.title {
  composes: input;
  margin: 0 16px 0 8px;
  height: 21px;
  flex-grow: 1;
  outline: none;
}

.title:not(:disabled) {
  background: var(--base-dark-d);
  padding: 0 8px;
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:global(.theme-light) .title:not(:disabled) {
  background: var(--base-light-a);
}

.title:focus {
  box-shadow: 0 0 0 1px var(--teal-b);
}

.author {
  composes: row;
  display: block;
}

.avatar {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  overflow: hidden;
}

.username {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  vertical-align: top;
  text-decoration: none;
  color: var(--teal-c);
}

:global(.theme-light) .username {
  color: var(--teal-d);
}

.username:hover {
  text-decoration: underline;
}

.dates {
  composes: row;
}

.created,
.updated {
  display: inline-block;
}

.created {
  margin-right: 24px;
}

.dateHeading {
  display: block;
  font-size: 12px;
}

.dateValue {
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.source {
  composes: row;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sourceValue {
  background: #31393d;
  color: var(--base-light-a);
  font-size: 12px;
  padding: 0 8px;
  line-height: 23px;
  border-radius: 2px;
  transition: background var(--theme-toggle-transition),
    color var(--theme-toggle-transition);
}

.sourceValueLink {
  composes: sourceValue;
  background: var(--teal-e);
  text-decoration: none;
  color: var(--base-dark-a);
  font-weight: 500;
}

:global(.theme-light) .sourceValue {
  color: var(--base-dark-a);
  background: #cfdee6;
}

.textareaContainer {
  margin-bottom: 16px;
}

.extraButtons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.extraButton {
  border: 0;
  margin-top: 16px;
  background: var(--teal-e);
  color: var(--base-dark-a);
  font-size: 12px;
  font-weight: 500;
  padding: 0 8px;
  line-height: 23px;
  border-radius: 2px;
  cursor: pointer;
  transition: background var(--theme-toggle-transition),
    color var(--theme-toggle-transition);
}

.publishedToggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 16px;
}

.styledToggle {
  width: 22px;
  height: 12px;
  border-radius: 12px;
  background: var(--teal-c);
  margin: 0 20px;
  position: relative;
  transition: background 0.2s ease-in-out;
}

.styledToggleActive {
  composes: styledToggle;
  background: var(--teal-e);
}

.styledToggle:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  top: 2px;
  left: 2px;
  background: var(--base-dark-b);
  transition: left 0.1s ease-in-out, background var(--theme-toggle-transition);
}

.styledToggleActive:before {
  left: 12px;
}

:global(.theme-light) .styledToggle:before {
  background: var(--base-light-b);
}

.hiddenRadioButton {
  visibility: hidden;
}

.deleteButton {
  composes: extraButton;
  background: var(--red-b);
}

.templateButton {
  composes: extraButton;
}
