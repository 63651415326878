.button {
  background: transparent;
  border: 0;
  color: #fff;
  opacity: 0.75;
  cursor: pointer;
  outline: none;

  transition: opacity var(--hover-transition);
}

:global(.theme-light) .button {
  color: var(--teal-d);
}

.button:hover:not(:disabled) {
  opacity: 1;
}

.button:disabled {
  opacity: 0.1;
  pointer-events: none;
}
