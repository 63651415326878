.CodeEditor {
  padding-top: var(--header-heights);
  height: 100%;
  position: relative;
}

.monacoContainer {
  position: absolute;
  width: 100%;
  height: calc(100% - var(--code-comment-height) - var(--header-heights));
  overflow: hidden;

  transition: height var(--theme-toggle-transition);
}

.CodeEditor :global(.editingLine) {
  background: var(--yellow-a);
  color: var(--base-dark-b);
}

.ResizeHandleMobileContainer {
  width: 12px;
  height: 100%;
  position: absolute;
  background: var(--base-dark-a);
  z-index: 1;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .ResizeHandleMobileContainer {
  background: var(--base-light-a);
}

.ResizeHandleMobile {
  position: absolute;
  display: none;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  display: block;
}

@media (min-width: 768px) {
  .ResizeHandleMobileContainer {
    display: none;
  }
}

:global(.monaco-editor) * {
  transition: color var(--theme-toggle-transition),
    background var(--theme-toggle-transition),
    border var(--theme-toggle-transition);
}

.CodeEditor :global(.monaco-editor .monaco-editor-background),
.CodeEditor :global(.monaco-editor .margin) {
  background: var(--base-dark-b);
}

:global(.theme-light)
  .CodeEditor
  :global(.monaco-editor .monaco-editor-background),
:global(.theme-light) .CodeEditor :global(.monaco-editor .margin) {
  background: var(--base-light-b);
}

.header {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: var(--header-heights);
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
}

.header::-webkit-scrollbar {
  display: none;
}

.fileTabs {
  list-style: none;
}

.fileTabs li {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}

.fileTab {
  background: var(--base-dark-c);
  color: var(--teal-d);
  padding: 0 25px 0 19px;
  font-size: 13px;
  border: 0;
  min-width: 30px;
  height: calc(var(--header-heights) - 1px);
  line-height: var(--header-heights);
  cursor: pointer;
  outline: none;
  position: relative;
  user-select: none;

  transition: color var(--hover-transition), padding var(--hover-transition),
    opacity var(--hover-transition), background var(--theme-toggle-transition);
}

:global(.theme-light) .fileTab {
  background: var(--base-light-c);
}

.fileTab:hover {
  color: var(--base-light-a);
  padding: 0 30px 0 14px;
}

:global(.theme-light) .fileTab:hover {
  background: var(--base-light-b);
  color: var(--base-dark-a);
}

.fileTab:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: var(--base-dark-a);
  width: 100%;
  height: 2px;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .fileTab:before {
  background: var(--base-light-a);
}

.fileTabSelected {
  background: var(--base-dark-b);
  color: var(--base-light-a);

  transition: color var(--hover-transition), padding var(--hover-transition),
    opacity var(--hover-transition), background var(--theme-toggle-transition);
}

:global(.theme-light) .fileTabSelected {
  background: var(--base-light-b);
  color: var(--teal-d);
}

.fileTabPreviewing {
  font-style: italic;
}

.fileTabSelected:before {
  background: var(--teal-a);
  height: 1px;
}

:global(.theme-light) .fileTabSelected:before {
  background: var(--teal-a);
  height: 1px;
}

.fileTab:after {
  content: '';
  position: absolute;
  z-index: 2;
  right: 1px;
  top: -3px;
  transform: rotate(-10deg);
  background: var(--base-dark-a);
  width: 3px;
  height: 39px;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .fileTab:after {
  background: var(--base-light-a);
}

.fileTabs li:last-child .fileTab:after {
  width: 8px;
  right: -3px;
}

.closeTabButton {
  position: absolute;
  padding: 0;
  width: 12px;
  height: 12px;
  border: 0;
  padding: 2px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  cursor: pointer;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--hover-transition);
}

.fileTab:hover .closeTabButton {
  opacity: 0.4;
  pointer-events: all;
}

.fileTabText {
  opacity: 1;
  transition: opacity var(--hover-transition);
}

.closeTabButton:hover {
  opacity: 1 !important;
}

.closeTabButton:hover + .fileTabText {
  opacity: 0.2;
}

.fileTabs li:first-child:last-child .fileTab:hover .closeTabButton {
  opacity: 0;
  pointer-events: none;
}
