:root {
  --handleGirthMobile: 4px;
  --handleGirthDesktop: 2px;
}

.ResizeHandle {
  width: var(--handleGirthMobile);
  height: 56px;
  border-radius: 2px;
  background: #408080;
  z-index: 100;
  box-shadow: 0 0 2px 1px var(--base-dark-a);
  cursor: col-resize;

  transition: box-shadow var(--theme-toggle-transition);
}

:global(.theme-light) .ResizeHandle {
  box-shadow: 0 0 2px 1px var(--base-light-a);
}

.ResizeHandle:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}

.horizontal {
  height: var(--handleGirthMobile);
  width: 56px;

  cursor: row-resize;
}

@media (min-width: 768px) {
  .ResizeHandle {
    width: var(--handleGirthDesktop);
    height: 40px;
  }

  .horizontal {
    height: var(--handleGirthDesktop);
    width: 40px;
  }
}
