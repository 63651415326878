.fileBrowser {
  --folder-indent: 20px;
  width: 100%;
  border-bottom: 4px solid var(--base-dark-a);

  transition: border-bottom var(--theme-toggle-transition);
}

:global(.theme-light) .fileBrowser {
  border-bottom: 4px solid var(--base-light-a);
}

.header {
  height: var(--header-heights);
  display: flex;
}

.headerTitle {
  position: relative;
  color: var(--base-light-a);
  font-size: 13px;
  line-height: var(--header-heights);
  padding-left: 16px;
  flex-grow: 1;
  overflow: hidden;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .headerTitle {
  color: var(--base-dark-a);
}

.headerTitle:before {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: var(--teal-a);
  width: 100%;
  height: 1px;
}

.headerTitle:after {
  content: '';
  position: absolute;
  z-index: 2;
  right: -12px;
  top: -5px;
  transform: rotate(-10deg);
  background: var(--base-dark-a);
  width: 20px;
  height: 120%;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .headerTitle:after {
  background: var(--base-light-a);
}

.headerButtons {
  background: var(--base-dark-a);
  display: flex;
  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .headerButtons {
  background: var(--base-light-a);
}

.headerButton {
  color: var(--teal-c);
}

.headerButtons button {
  color: var(--teal-c);
}

.contentContainer {
  background: var(--base-dark-b);
  overflow: hidden;
  transition: height ease-in-out 0.2s, background var(--theme-toggle-transition);
}

:global(.theme-light) .contentContainer {
  background: var(--base-light-b) !important;
}

.fileFolderList {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}

.folderCollapsed ~ .fileFolderList {
  display: none;
}

.fileFolderListRoot {
  composes: fileFolderList;
  padding-bottom: 32px;
}

.itemContainer .fileFolderList:before {
  content: '';
  position: absolute;
  left: 22px;
  top: 0px;
  width: 1px;
  height: 100%;
  background: rgba(0, 255, 255, 0.2);
}

.fileOrFolderItem > i,
.fileOrFolderTextInputContainer > i {
  width: 22px;
  margin-right: 6px;
}

.fileOrFolderItem:hover {
  color: var(--base-light-b);
}

:global(.theme-light) .fileOrFolderItem:hover {
  color: #56666d;
}

:global(.theme-light) .fileOrFolderItem > i {
  opacity: 0.7;

  transition: opacity var(--theme-toggle-transition);
}

:global(.theme-light) .fileOrFolderItem:hover > i {
  color: #6a7d87;
  opacity: 1;
}

.fileFolderList ol,
.fileFolderList li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* vertical line indents */
.itemContainer .itemContainer .fileFolderList:before {
  left: calc(19px + var(--folder-indent));
}

.itemContainer .itemContainer .itemContainer .fileFolderList:before {
  left: calc(16px + var(--folder-indent) * 2);
}

.itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .fileFolderList:before {
  left: calc(15px + var(--folder-indent) * 3);
}

.itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .fileFolderList:before {
  left: calc(14px + var(--folder-indent) * 4);
}

.itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .fileFolderList:before {
  left: calc(11px + var(--folder-indent) * 5);
}

/* row indents */
.itemContainer .itemContainer .itemContent {
  padding-left: calc(8px + var(--folder-indent));
}

.itemContainer .itemContainer .itemContainer .itemContent {
  padding-left: calc(6px + var(--folder-indent) * 2);
}

.itemContainer .itemContainer .itemContainer .itemContainer .itemContent {
  padding-left: calc(4px + var(--folder-indent) * 3);
}

.itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContent {
  padding-left: calc(6px + var(--folder-indent) * 4);
}

.itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContainer
  .itemContent {
  padding-left: calc(6px + var(--folder-indent) * 5);
}

.fileOrFolderItem {
  composes: itemContent;
  display: flex;
  width: 100%;
  height: 30px;
  padding: 2px 5px 2px 12px;
  border: 0;
  margin: 0;
  background: transparent;
  color: var(--teal-c);
  font-size: 12px;
  cursor: pointer;
  outline: none;

  transition: color var(--hover-transition);
}

.fileFolderName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 25px;
}

.focused {
  background: var(--teal-b-opaque);
}

/* folderError */

.fileOrFolderItemEditing {
  composes: fileOrFolderItem;
  background: var(--teal-b-opaque);
  color: #fff;
  position: relative;
}

.fileOrFolderTextInput {
  flex-grow: 1;
  color: var(--base-light-b);
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  border: 0;
  display: inline-block;
  margin: 2px 3px 0 0;
  padding: 0 5px;
  background: transparent;
  outline: none;
  border: 1px solid var(--teal-b);
  border-radius: 2px;
}

.error .fileOrFolderTextInput {
  border: 1px solid var(--red-b);
}

.errorMessage {
  position: absolute;
  padding: 2px 8px;
  top: 100%;
  right: 7px;
  color: var(--red-b);
  background: var(--base-dark-b);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  cursor: default;
}

.draggingOver {
  background: rgba(255, 255, 255, 0.2);
}

.fileHoverButtonRow {
  display: flex;
  margin-left: auto;
}

.fileHoverButtonRow > button {
  background: none;
  outline: none;
  border: none;
  color: var(--teal-c);
  opacity: 0.75;
  padding: 0 2px;
  margin: 0 2px;

  transition: opacity var(--hover-transition);
}

.fileHoverButtonRow > button svg {
  width: 20px;
  height: 20px;
}

.fileHoverButtonRow > button:hover {
  opacity: 1;
  display: block;
  cursor: pointer;
}

.fileOrFolderItem:not(:hover) > .fileHoverButtonRow {
  display: none;
}
