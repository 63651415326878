.ResizeHandle {
  position: absolute;
  left: 50%;
  top: -8px;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .ResizeHandle {
    top: -3px;
  }
}

.header {
  height: 36px;
  border-bottom: 1px solid var(--base-dark-d);
  background: var(--base-dark-a);
  display: flex;
  position: fixed;
  top: 0;
  z-index: 3;

  transition: background var(--theme-toggle-transition),
    border-bottom var(--theme-toggle-transition);
}

:global(.theme-light) .header {
  background: var(--base-light-a);
  border-bottom: 1px solid var(--base-light-d);
}

.header:after {
  content: '';
  position: absolute;
  width: 4px;
  height: 22px;
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, rgba(32, 33, 33, 0) 0%, #202121 76.04%);

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .header:after {
  background: linear-gradient(
    270deg,
    rgba(32, 33, 33, 0) 0%,
    rgba(32, 33, 33, 0) 0%
  );
}

.playPauseButton {
  margin: 0 16px;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .playPauseButton {
  color: var(--teal-c);
}

.main {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  overflow-y: auto;
  padding-top: 36px;
}

.inputs {
  position: relative;
  z-index: 2;
  min-width: 250px;
}

.inputs:after {
  content: '';
  position: absolute;
  width: 4px;
  height: 100%;
  right: -4px;
  top: 0;
  background: linear-gradient(270deg, rgba(32, 33, 33, 0) 0%, #202121 76.04%);

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .inputs:after {
  background: linear-gradient(
    270deg,
    rgba(252, 255, 255, 0) 0%,
    #dee5e5 76.04%
  );
}

.currentTime {
  color: var(--teal-c);
  font-size: 12px;
  border: 1px solid var(--teal-c);
  padding: 2px 8px;
  border-radius: 2px;
  display: inline-block;
  margin: 4px;

  transition: border var(--theme-toggle-transition);
}

:global(.theme-light) .currentTime {
  border: 1px solid var(--base-dark-d);
}

.totalTime {
  color: var(--teal-c);
  font-size: 12px;
  display: inline-block;
  margin: 4px 0 4px 60px;

  transition: color var(--theme-toggle-transition);
}

:global(.theme-light) .totalTime {
  color: var(--base-dark-d);
}

.tracks {
  position: relative;
}
