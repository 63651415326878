.tags {
  text-align: right;
}

.tag {
  position: relative;
  display: inline-block;
  background: var(--teal-e);
  max-width: calc(100% - 16px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--base-dark-b);
  padding: 0 12px 0 16px;
  line-height: 21px;
  font-size: 12px;
  border-radius: 2px;
  border: 0;
  outline: none;
  margin: 0 0 8px 8px;

  transition: color var(--theme-toggle-transition),
    padding var(--hover-transition);
}

:global(.theme-light) .tag {
  color: var(--base-light-a);
}

.tag:before {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--base-dark-b);
  opacity: 1;

  transition: background var(--theme-toggle-transition),
    opacity var(--hover-transition);
}

:global(.theme-light) .tag:before {
  background: var(--base-light-a);
}

.closeIcon {
  position: absolute;
  width: 7px;
  height: 7px;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  color: var(--base-dark-c);

  transition: opacity var(--hover-transition);
}

:global(.theme-light) .closeIcon {
  color: var(--base-light-a);
}

.isOwner .tag:hover {
  cursor: pointer;
  padding: 0 20px 0 8px;
}

.isOwner .tag:hover:before {
  opacity: 0;
}

.isOwner .tag:hover .closeIcon {
  opacity: 1;
}

.isOwner {
  display: flex;
  flex-wrap: wrap;
  background: var(--base-dark-d);
  padding: 6px 0 0 0;
  border-radius: 2px;
  text-align: left;

  transition: background var(--theme-toggle-transition);
}

:global(.theme-light) .isOwner {
  background: var(--base-light-a);
}

.isOwner:focus-within {
  box-shadow: 0 0 0 1px var(--teal-b);
}

.addTagInput {
  background: transparent;
  margin: 0 0 8px 8px;
  border: 0;
  outline: none;
  color: var(--teal-b);
  width: 80px;
  flex-grow: 1;
}
